<template>
  <div class="oznam" v-if="show">
    <marquee
      >Vážení zákazníci, predajňa z dôvodu začatia zimnej sezóny bude od
      {{dateToSkString(from)}} do {{dateToSkString(to)}} ZATVORENÁ. V prípade záujmu si dohodnite stretnutie
      na telefónnom čísle
      <a href="callto:+421903137718">+421 903 137 718</a
      >.</marquee
    >
  </div>
</template>

<style>
.oznam {
  background:#dc3545;
  color: white;
  line-height: 1;
  padding: 1rem 0;
}
.oznam a {
    color: white;
}
</style>

<script>
export default {
  data() {
    return {
      today: new Date(),
      from: new Date(2023, 11, 1),
      to: new Date(2024, 1, 26),
    };
  },
  computed: {
      show : function () {
          return this.today < this.to;
      }
  },
  methods: {
    dateToSkString(date) {
      if (date === undefined || date instanceof Date == false) return "";

      var options = { year: 'numeric', month: 'long', day: 'numeric' };

      return date.toLocaleDateString("sk-SK", options);
    }
  }
};
</script>